import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import fbIcon from "../../images/facebook_icon.png";
import instaIcon from "../../images/insta_icon.png";
import FooterLogo from "../../images/main_logo.png";
import twitterIcon from "../../images/twitter_icon.png";
import youtubeIcon from "../../images/youtube_icon.png";
import { sendIcon } from '../../Util/Svgs';
import { scroller } from 'react-scroll';
import './Footer.css';
import { useLocation, useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigateToHomeSection = (section) => {
    if (location.pathname !== '/') {
      navigate(`/#${section}`);
    } else {
      navigate(`/#${section}`);
      scroller.scrollTo(section, {});
    }
  };

  return (
    <div className='footer_main'>
      <Container>
        <Row className='align-items-end'>
          <Col md={6}>
            <img src={FooterLogo} alt='' className='footer_logo' />
            <p>Copyright © 2024 AJCL Pvt Ltd.</p>
            <p>All rights reserved</p>

            <div className='social_media'>
              <img src={fbIcon} alt='' />
              <img src={instaIcon} alt='' />
              <img src={twitterIcon} alt='' />
              <img src={youtubeIcon} alt='' />
            </div>
          </Col>
          <Col md={6}>
            <div className='footer_right_section'>
              <Row>
                <Col md={3}>
                  <h6>Company</h6>
                  <ul>
                    <li onClick={() => handleNavigateToHomeSection("about")}>About us</li>
                    <li>Blog</li>
                    <li onClick={() => navigate("/contact")}>Contact us</li>
                    <li onClick={() => navigate("/pricing")}>Pricing</li>
                    <li>Testimonials</li>
                  </ul>
                </Col>
                <Col md={4}>
                  <h6>Support</h6>
                  <ul>
                    <li>Help center</li>
                    <li>Terms of service</li>
                    <li>Legal</li>
                    <li>Privacy policy</li>
                    <li>Status</li>
                  </ul>
                </Col>
                <Col md={5}>
                  <h6>Stay up to date</h6>
                  <div className='up_to_date'>
                    <input placeholder='Your email address' />
                    <div>{sendIcon}</div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Footer