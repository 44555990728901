import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Element, scroller } from 'react-scroll';
import BusinessStats from '../../Components/BusinessStats/BusinessStats';
import HeroSection from '../../Components/HeroSection/HeroSection';
import Solutions from '../../Components/Solutions/Solutions';
import WhoWeAre from '../../Components/WhoWeAre/WhoWeAre';
import './Home.css';

const Home = () => {
  const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const section = location.hash.replace('#', ''); // remove the hash symbol
            scroller.scrollTo(section, {
                // smooth: true,
                // duration: 500,
                // offset: -70,
            });
        }
    }, [location]);

  return (
    <div>
      <HeroSection />
      <div className='established_div'>
        <h6><b>Established in 2012,</b> Trusted by leading companies.</h6>
      </div>
      <Element name="solutions"> <Solutions /> </Element>
      <Element name="about"> <WhoWeAre /> </Element>
      <BusinessStats />
    </div>
  )
}

export default Home