import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SolutionRightImg from "../../images/solutions_right_img.png";
import { activityLogIcon, alprIcon, parkingApplicationIcon, paymentIcon } from '../../Util/Svgs';
import './Solutions.css';

const Solutions = () => {
  const features = [
    { icon: alprIcon, title: 'Automatic License Plate Recognition (ALPR)', description: 'For fast and seamless entry and exit.' },
    { icon: paymentIcon, title: 'Contactless Payment Options', description: 'Secure, cashless payments via mobile or online platforms.' },
    { icon: parkingApplicationIcon, title: 'Realtime Parking Application', description: 'Instantly pay for available parking spots, ensuring a quick and hassle-free experience.' },
    { icon: activityLogIcon, title: 'Instant Activity Log', description: 'A real-time record of all your parking transactions, providing instant access to your payment and parking history.' }
  ];

  return (
    <div className="features-section" id='solutions'>
      <Container>
        <Row className='justify-content-between align-items-center'>
          <Col md={5}>
            <h2>Elevating the <b>Parking</b> Experience with <b>Smarter Solutions</b></h2>
            <p>At <b>Smart Parking</b>, we’re transforming parking with seamless digital solutions that simplify the entire process.
              Our smart technology allows users to quickly find available spaces and make secure, contactless payments through an
              intuitive platform. With real-time data and flexible options for short-term or long-term parking,
              we ensure a convenient, efficient experience from start to finish.</p>
            <Row>
              {features.map((feature, index) => (
                <Col md={6} key={index}>
                  {feature.icon}
                  <h5>{feature.title}</h5>
                  <p>{feature.description}</p>
                </Col>
              ))}
            </Row>
          </Col>
          <Col md={5}>
            <img src={SolutionRightImg} alt='' />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Solutions;