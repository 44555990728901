import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import whoWeIcon1 from "../../images/who_we_icon1.png";
import whoWeIcon2 from "../../images/who_we_icon2.png";
import whoWeIcon3 from "../../images/who_we_icon3.png";
import whoWeIcon4 from "../../images/who_we_icon4.png";
import './WhoWeAre.css';

const WhoWeAre = () => {
    return (
        <div className='who_we_are_main' id='about'>
            <Container>
                <h1>Who We Are</h1>
                <p>The Smart Parking System is one of AJCL Private Limited's innovative projects. AJCL, a diversified trading, distribution,
                    and technical service provider, has been connecting Pakistan to the global market since 1949. With a worldwide presence,
                    the company's products and services have played a significant role in national development and enhancing regional
                    connectivity. Building on over 70 years of experience, AJCL was formally established as an independent entity in 2012,
                    continuing its tradition of exceptional service across sectors such as aviation, commodities, energy, ports & shipping,
                    oil & gas, machinery, and special projects. Our adherence to high ethical standards and professionalism has established
                    us as a preferred partner for many international companies in these fields. Our commitment to ethical standards and
                    professionalism has made us the top choice for international companies in these sectors.</p>

                <Row>
                    <Col md={3}>
                        <img src={whoWeIcon1} alt='' />
                        <h6>Promoting <br />
                            investment and trade between <br />
                            Pakistan & the region</h6>
                    </Col>
                    <Col md={3}>
                        <img src={whoWeIcon2} alt='' />
                        <h6>Act as a conduit for <br /> exceptional global-to-local <br /> connectivity.</h6>
                    </Col>
                    <Col md={3}>
                        <img src={whoWeIcon3} alt='' />
                        <h6>Global presence with <br />
                        offices in key cities across <br /> Pakistan.</h6>
                    </Col>
                    <Col md={3}>
                        <img src={whoWeIcon4} alt='' />
                        <h6>International ties have led AJCL <br />
                        to engage in key projects.</h6>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default WhoWeAre;