import React, { useEffect, useState } from 'react';
import { Button, Container, Nav, Navbar } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';
import mainLogo from "../../images/main_logo.png";
import './Header.css';

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false)

  const handleNavigateToHomeSection = (section) => {
    if (location.pathname !== '/') {
      navigate(`/#${section}`);
    } else {
      navigate(`/#${section}`);
      scroller.scrollTo(section, {});
    }
  };

  useEffect(() => {
    if(show){
      setShow(false)
    }
  }, [location.pathname, location.hash])

  return (
    <Navbar collapseOnSelect expand="lg" className="navbar-dark">
      <Container>
        <Navbar.Brand onClick={() => navigate("/")}>
          <img src={mainLogo} alt='' />
        </Navbar.Brand>
        <Navbar.Toggle onClick={() => setShow(!show)} />
        <Navbar.Collapse in={show}>
          <Nav className="ms-auto">
            <Nav.Link onClick={() => navigate("/")} className={(location.pathname === '/' && location.hash === '') ? "text-light active" : "text-light"}>Home</Nav.Link>
            <Nav.Link onClick={() => handleNavigateToHomeSection("solutions")} className={location.hash === '#solutions' ? "text-light active" : "text-light"}>Solutions</Nav.Link>
            <Nav.Link onClick={() => navigate("/pricing")} className={(location.pathname === '/pricing' && location.hash === '') ? "text-light active" : "text-light"}>Pricing</Nav.Link>
            <Nav.Link onClick={() => handleNavigateToHomeSection("about")} className={location.hash === '#about' ? "text-light active" : "text-light"}>About Us</Nav.Link>
          </Nav>
          <Button onClick={() => navigate('/contact')}>Contact Us</Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
